<template>
    <vx-card :title="'Work Order Execution - Putaway'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button
                class="ml-4 mt-2"
                color="danger"
                icon-pack="feather"
                icon="icon-arrow-left"
                @click="handleBack()"
            >Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Bill Of Material ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="bomCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order ID</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="woCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Work Order Description</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="description" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="warehouseCode+' / '+warehouseName" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <br>
        <div class="vx-row-mb-6">
            <div class="vx-col w-full">
                <h3>Parent Item</h3>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="35%">SKU Code</th>
                            <th width="10%">Qty</th>
                            <th width="10%">Default Qty</th>
                            <th width="10%">Actual Qty</th>
                            <th width="10%">Unit</th>
                            <th width="15%">Price</th>
                            <th width="10%">Settlement</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="parentLine.sku_code+' / '+parentLine.item_name" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedQty" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedDefaultQty" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedActualQty" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="parentLine.unit" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="formattedPrice" readonly></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" type="text" :value="parentLine.settlement" readonly></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h3>Putaway Items</h3>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="30%">SKU Code</th>
                            <th width="15%">Batch</th>
                            <th width="10%">Actual Qty (UOM)</th>
                            <th width="10%">Putaway Qty</th>
                            <th width="10%">Unit</th>
                            <th width="10%">UOM</th>
                            <th width="15%">Storage Area</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in actualLine">
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.sku_code+' / '+tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.batch+' / '+tr.exp_date" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first line -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        :min="0"
                                        v-model="tr.putaway_lines[0].putaway_qty">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :clearable="false"
                                        :options="tr.option_unit"
                                        v-model="tr.putaway_lines[0].unit"
                                        class="w-full"
                                        @input="(val) => {onChangeUnit(val, index, 0, 'actual')}"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.putaway_lines[0].uom" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :clearable="false"
                                        class="w-full"
                                        :options="tr.option_warehouse_area"
                                        v-model="tr.putaway_lines[0].storage_area"
                                    />
                                </td>
                                <td class="td vs-table--td align-top pt-2">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="tr.putaway_lines.length > 1"
                                            @click.stop="removeRow(index, 0, 'actual')" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row"
                                        />
                                        <vs-button @click.stop="addRow(index, 'actual')" size="small" color="success"
                                            v-if="tr.putaway_lines.length == 1" icon-pack="feather" icon="icon-plus"
                                            title="Add Row"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(addLine, indexAddLine) in actualLine[index].putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input 
                                            type="number" 
                                            class="w-full" 
                                            :min="0"
                                            v-model="addLine.putaway_qty">
                                        </vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :clearable="false"
                                            :options="tr.option_unit"
                                            v-model="addLine.unit"
                                            class="w-full"
                                            @input="(val) => {onChangeUnit(val, index, indexAddLine, 'actual')}"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="addLine.uom" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <v-select
                                            :filterable="false"
                                            :clearable="false"
                                            class="w-full"
                                            :options="tr.option_warehouse_area"
                                            v-model="addLine.storage_area"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top pt-2">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="tr.putaway_lines.length > 0"
                                                @click.stop="removeRow(index, indexAddLine, 'actual')" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row"
                                            />
                                            <vs-button @click.stop="addRow(index, 'actual')" size="small" color="success"
                                                v-if="tr.putaway_lines.length == indexAddLine + 1"
                                                icon-pack="feather" icon="icon-plus" title="Add Row"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div v-if="leftOverLines.length > 0" class="vx-row mb-6">
            <div class="vx-col w-full">
                <h3>Leftover Items</h3>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="30%">SKU Code</th>
                            <th width="15%">Batch</th>
                            <th width="10%">Actual Qty (UOM)</th>
                            <th width="10%">Putaway Qty</th>
                            <th width="10%">Unit</th>
                            <th width="10%">UOM</th>
                            <th width="15%">Storage Area</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in leftOverLines">
                            <!-- item line -->
                            <tr style="padding-bottom: 5px;" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.sku_code+' / '+tr.item_name" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.batch+' / '+tr.exp_date" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.qty" :readonly="true" style="width: auto;"></vs-input>
                                </td>

                                <!-- first line -->
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        :min="0"
                                        v-model="tr.putaway_lines[0].putaway_qty">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :clearable="false"
                                        :options="tr.option_unit"
                                        v-model="tr.putaway_lines[0].unit"
                                        class="w-full"
                                        @input="(val) => {onChangeUnit(val, index, 0, 'leftover')}"
                                    />
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px;">
                                    <vs-input :value="tr.putaway_lines[0].uom" :readonly="true" style="width: auto;"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <v-select
                                        :filterable="false"
                                        :clearable="false"
                                        class="w-full"
                                        :options="tr.option_warehouse_area"
                                        v-model="tr.putaway_lines[0].storage_area"
                                        label="label"
                                    />
                                </td>
                                <td class="td vs-table--td align-top pt-2">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="tr.putaway_lines.length > 1"
                                            @click.stop="removeRow(index, 0, 'leftover')" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                            title="Remove Row"
                                        />
                                        <vs-button @click.stop="addRow(index, 'leftover')" size="small" color="success"
                                            v-if="tr.putaway_lines.length == 1" icon-pack="feather" icon="icon-plus"
                                            title="Add Row"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <!-- add lines -->
                            <template v-for="(addLine, indexAddLine) in tr.putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + indexAddLine" v-if="indexAddLine > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input 
                                            type="number" 
                                            class="w-full" 
                                            :min="0"
                                            v-model="addLine.putaway_qty">
                                        </vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <v-select
                                            :filterable="false"
                                            :clearable="false"
                                            :options="tr.option_unit"
                                            v-model="addLine.unit"
                                            class="w-full"
                                            @input="(val) => {onChangeUnit(val, index, indexAddLine, 'leftover')}"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <vs-input :value="addLine.uom" :readonly="true" style="width: auto;"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px;">
                                        <v-select
                                            :filterable="false"
                                            :clearable="false"
                                            class="w-full"
                                            :options="tr.option_warehouse_area"
                                            v-model="addLine.storage_area"
                                            label="label"
                                        />
                                    </td>
                                    <td class="td vs-table--td align-top pt-2">
                                        <div class="vx-input-group flex mx-auto">
                                            <vs-button v-if="tr.putaway_lines.length > 0"
                                                @click.stop="removeRow(index, indexAddLine, 'leftover')" size="small" color="danger"
                                                icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                                title="Remove Row"
                                            />
                                            <vs-button @click.stop="addRow(index, 'leftover')" size="small" color="success"
                                                v-if="tr.putaway_lines.length == indexAddLine + 1"
                                                icon-pack="feather" icon="icon-plus" title="Add Row"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="handleSave">Save</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            status: null,
            woCode: null,
            description: null,
            bomCode: null,
            warehouseID: 0,
            warehouseCode: null,
            warehouseName: null,
            parentLine: {
                sku_code: null,
                item_name: null,
                quantity: 0,
                default_qty: 0,
                actual_qty: 0,
                unit: null,
                price: 0,
                settlement: null,
            },
            formattedQty: null,
            formattedDefaultQty: null,
            formattedActualQty: null,
            formattedPrice: null,
            actualLine: [
                {
                    sku_code: null,
                    item_name: null,
                    option_unit: [],
                    option_warehouse_area: [],
                    qty: 0,
                    batch: null,
                    exp_date: null,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            putaway_qty: 0,
                            unit: null,
                            uom: null,
                            storage_area: null,
                        }
                    ]
                }
            ],
            leftOverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    option_unit: [],
                    option_warehouse_area: [],
                    qty: 0,
                    batch: null,
                    exp_date: null,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            putaway_qty: 0,
                            unit: null,
                            uom: null,
                            storage_area: null,
                        }
                    ]
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/work-order-execution/putaway-form/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.woCode = resp.data.wo_code
                    this.description = resp.data.wo_description
                    this.bomCode = resp.data.bom_code
                    this.warehouseID = resp.data.warehouse_id
                    this.warehouseCode = resp.data.warehouse_code
                    this.warehouseName = resp.data.warehouse_name
                    this.parentLine = resp.data.parent_lines
                    this.actualLine = resp.data.actual_lines
                    this.leftOverLines = resp.data.leftover_lines

                    this.formattedQty = this.handleUpdateFormat(this.parentLine.quantity)
                    this.formattedDefaultQty = this.handleUpdateFormat(this.parentLine.default_qty)
                    this.formattedActualQty = this.handleUpdateFormat(this.parentLine.actual_qty)
                    this.formattedPrice = this.handleUpdateCurrency(this.parentLine.price)

                    for (let i = 0; i < this.actualLine.length; i++) {
                        this.handleGetUnitOption(i, this.actualLine[i].sku_code, "actual")
                        this.handleGetStorageOption(i, "actual")
                        for (let j = 0; j < this.actualLine[i].putaway_lines.length; j++) {
                            this.handleGetUom(i, j, this.actualLine[i].sku_code, this.actualLine[i].putaway_lines[j].unit, 'actual')
                        }
                    }

                    if (this.leftOverLines.length > 0) {
                        for (let i = 0; i < this.leftOverLines.length; i++) {
                            this.handleGetUnitOption(i, this.leftOverLines[i].sku_code, "leftover")
                            this.handleGetStorageOption(i, "leftover")
                            for (let j = 0; j < this.leftOverLines[i].putaway_lines.length; j++) {
                                this.handleGetUom(i, j, this.leftOverLines[i].sku_code, this.leftOverLines[i].putaway_lines[j].unit, 'leftover')
                            }
                        }
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                console.log(error);
                this.$vs.loading.close();
            });
        },
        handleSave() {
            let message = "";
            let skusActual = [];
            let skusLeftover = [];

            // actual lines
            this.actualLine.forEach((val) => {
                let putawayLines = [];

                val.putaway_lines.forEach((idx) => {
                    if (idx.putaway_qty < 1) {
                        message = "putaway qty must be greater than zero for item "+val.item_name;
                        return false;
                    }

                    if (!idx.unit) {
                        message = "unit is required for item "+val.item_name;
                        return false;
                    }

                    if (!idx.storage_area) {
                        message = "storage area is required for item "+val.item_name;
                        return false;
                    }

                    putawayLines.push({
                        putaway_id: idx.putaway_id ? 0 : idx.putaway_id,
                        putaway_qty: Number(idx.putaway_qty),
                        unit: idx.unit,
                        storage_area: idx.storage_area,
                    });
                });

                skusActual.push({
                    sku_code: val.sku_code,
                    batch: val.batch,
                    putaway_lines: putawayLines,
                });
            });

            // leftover lines
            if (this.leftOverLines.length > 0) {
                this.leftOverLines.forEach((val) => {
                    let putawayLines = [];

                    val.putaway_lines.forEach((idx) => {
                        if (idx.putaway_qty < 1) {
                            message = "putaway qty must be greater than zero for leftover item "+val.item_name;
                            return false;
                        }

                        if (!idx.unit) {
                            message = "unit is required for leftover item "+val.item_name;
                            return false;
                        }

                        if (!idx.storage_area) {
                            message = "storage area is required for leftover item "+val.item_name;
                            return false;
                        }

                        putawayLines.push({
                            putaway_id: idx.putaway_id == null ? 0 : idx.putaway_id,
                            putaway_qty: Number(idx.putaway_qty),
                            unit: idx.unit,
                            storage_area: idx.storage_area,
                        });
                    });

                    skusLeftover.push({
                        line_id: val.line_id,
                        sku_code: val.sku_code,
                        batch: val.batch,
                        putaway_lines: putawayLines,
                    });
                });
            }

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("skusActual", JSON.stringify(skusActual));
            formData.append("skusLeftover", JSON.stringify(skusLeftover));

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.put("/api/wms/v1/work-order-execution/save-putaway/" + this.id, formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check-circle",
                            });
                            this.$vs.loading.close();
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            });
        },
        handleBack() {
            this.$router.push({
                name: "work-order-execution",
            });
        },
        handleGetUnitOption(index, skuCode, type) {
            this.$http.get("/api/wms/v1/work-order-execution/get-unit-options", {
                params: {
                    sku_code: skuCode,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (type == "actual") {
                        this.actualLine[index].option_unit = resp.data.options
                    } else {
                        this.leftOverLines[index].option_unit = resp.data.options
                    }
                } else {
                    if (type == "actual") {
                        this.actualLine[index].option_unit = []
                    } else {
                        this.leftOverLines[index].option_unit = []
                    }
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        handleGetStorageOption(index, type) {
            this.$http.get("/api/wms/v1/work-order-execution/get-storage-options", {
                params: {
                    warehouse_id: this.warehouseID,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (type == "actual") {
                        this.actualLine[index].option_warehouse_area = resp.data.options
                    } else {
                        this.leftOverLines[index].option_warehouse_area = resp.data.options
                    }
                } else {
                    if (type == "actual") {
                        this.actualLine[index].option_warehouse_area = []
                    } else {
                        this.leftOverLines[index].option_warehouse_area = []
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
        },
        handleGetUom(index, idx, skuCode, unit, type) {
            this.$http.get("/api/wms/v1/work-order-execution/get-uom", {
                params: {
                    sku_code: skuCode,
                    unit: unit,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    if (type == "actual") {
                        this.actualLine[index].putaway_lines[idx].uom = this.handleUpdateFormat(resp.data)
                    } else {
                        this.leftOverLines[index].putaway_lines[idx].uom = this.handleUpdateFormat(resp.data)
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        handleUpdateCurrency(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            let formattedValue = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 2
            }).format(cleanValue);
            return formattedValue;
        },
        onChangeUnit(val, index, idx, type) {
            var skuCode = ""
            if (type == 'actual') {
                skuCode = this.actualLine[index].sku_code
                this.handleGetUom(index, idx, skuCode, val, 'actual')
            } else {
                skuCode = this.leftOverLines[index].sku_code
                this.handleGetUom(index, idx, skuCode, val, 'leftover')
            }
        },
        removeRow(index, indexAddLine, type) {
            if (type == "actual") {
                this.actualLine[index].putaway_lines.splice(indexAddLine, 1);
            } else {
                this.leftOverLines[index].putaway_lines.splice(indexAddLine, 1);
            }
        },
        addRow(index, type) {
            if (type == "actual") {
                this.actualLine[index].putaway_lines.push({
                    putaway_id: 0,
                    putaway_qty: 0,
                    unit: null,
                    uom: null,
                    storage_area: null,
                });
            } else {
                this.leftOverLines[index].putaway_lines.push({
                    putaway_id: 0,
                    putaway_qty: 0,
                    unit: null,
                    uom: null,
                    storage_area: null,
                });
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>